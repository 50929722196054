<template>
  <BasicModal
    :action-title="actionTitle"
    :cancel-title="cancelTitle"
  >

    <h4>
      <template v-if="willSubscriptionCancelIntentionally">You have a downgrade pending</template>
      <template v-else>Downgrade to {{ selectedPlan.name }}?</template>
    </h4>

    <p v-if="willSubscriptionCancelIntentionally">
      You’re currently scheduled to downgrade from {{ userPlan.name }} to Free on {{ usageCycleEndsAt }}. Cancel this downgrade and change to {{ selectedPlan.name }} instead?
    </p>

    <p v-if="willPatchesPause">
      You’ve already used more than the {{ selectedPlan.name }} plan limit of {{ selectedPlan.metadata.maxEventsMonthly }} Actions.
    </p>

    <template v-if="selectedPlan.metadata.productSlug === 'free'">
      <p>
        You will keep your {{ userPlan.name }} plan benefits until <strong>{{ usageCycleEndsAt }}</strong>.
      </p>
      <p>
        <strong>Here’s what will change after {{ usageCycleEndsAt }}:</strong>
      </p>
    </template>
    <template v-else>
      <p>
        You will lose access to {{ userPlan.name }} benefits immediately.
      </p>
      <p>
        <strong>Here’s what will change:</strong>
      </p>
    </template>

    <div class="breakdown">
      <div class="line-item">
        <div class="old-value">
          <template v-if="userPlan.slug === 'free'">
            $0 / month
          </template>
          <template v-else>
            {{ formatNumberAsUSD(userPlan.stripePrice.unitAmount / (userPlan.billingInterval === 'yearly' ? 1200 : 100)) }} / {{ userPlan.billingInterval || 'month' }}
          </template>
        </div>
        <div><ArrowIcon /></div>
        <div class="new-value">
          <template v-if="selectedPlan.metadata.productSlug === 'free'">
            <span class="value">$0</span> / month
          </template>
          <template v-else>
            <span class="value">{{ formatNumberAsUSD(selectedStripePrice.unitAmount / (selectedStripePrice.recurring.interval === 'yearly' ? 1200 : 100)) }}</span> / {{ selectedStripePrice.recurring.interval || 'month' }}
          </template>
        </div>
      </div>
      <div class="line-item">
        <div class="old-value">{{ formatNumber(userPlan.actionEventLimit) }}</div>
        <div><ArrowIcon /></div>
        <div class="new-value"><span class="value">{{ formatNumber(selectedPlan.metadata.maxEventsMonthly) }}</span> monthly Actions</div>
      </div>
      <div class="line-item">
        <div class="old-value">{{ formatNumber(userPlan.maxEventsTimeframe / 86400000) }}</div>
        <div><ArrowIcon /></div>
        <div class="new-value"><span class="value">{{ formatNumber(Number.parseInt(selectedPlan.metadata.maxEventsTimeframe, 10) / 86400000) }}</span> days event history</div>
      </div>
      <div class="line-item" v-if="userPlan.slug === 'pro'">
        <div class="old-value">Priority support</div>
        <div><ArrowIcon /></div>
        <div>Standard support</div>
      </div>
    </div>
    <p v-if="selectedPlan.metadata.productSlug === 'free'">
      <template v-if="isPPAEnabled">
        <strong>Pay per Action will be deactivated;</strong> this add-on is only
        available for paid plans.
      </template>
      <template v-else>
        You will also lose the ability to activate Pay per Action.
      </template>
    </p>
    <p class="font-bold text-danger-700" v-if="willPatchesPause">
      Your Patches will pause immediately. You won’t be able to turn any Patches on until {{ usageCycleEndsAt }}.
    </p>

  </BasicModal>
</template>

<script>

  import { mapState, mapGetters } from 'vuex'

  import ArrowIcon from '@/assets/icons/arrow.svg'

  import useFilters from '@/composables/useFilters'

  import BasicModal from '@/components/modals/_BasicModal.vue'

  export default {
    inject: [
      '$mixpanel',
    ],
    components: {
      ArrowIcon,
      BasicModal,
    },
    props: {
      selectedStripePrice: {
        type: Object,
        required: true,
      },
    },
    setup(props) {

      const { formatNumber, formatNumberAsUSD } = useFilters()

      return {
        formatNumber,
        formatNumberAsUSD,
      }

    },
    computed: {
      ...mapState('app', ['stripePlansIdMap']),
      ...mapState('user', ['userPlan', 'userPlanStats']),
      ...mapGetters('user', [
        'isPPAEnabled',
        'actionsRemaining',
        'usageCycleEndsAt',
        'isNearActionLimit',
        'isOverActionLimit',
        'willSubscriptionCancelIntentionally',
      ]),
      actionTitle() {
        return this.willSubscriptionCancelIntentionally
          ? `Change to ${this.selectedPlan.name}`
          : 'Downgrade'
      },
      cancelTitle() {
        return this.willSubscriptionCancelIntentionally
          ? 'Keep scheduled downgrade'
          : 'Keep my current plan'
      },
      selectedPlan() {
        return this.stripePlansIdMap[this.selectedStripePrice.product]
      },
      willPatchesPause() {
        if (this.selectedPlan.metadata.productSlug === 'free') return false
        return this.userPlanStats.usage >= Number.parseInt(this.selectedPlan.metadata.maxEventsMonthly, 10)
      },
    },
    created() {
      this.$mixpanel.onReady((mixpanel) => {
        mixpanel.track('Plan Downgrade Confirmation Modal Opened', {
          isPPAEnabled: this.isPPAEnabled,
          usageCycleEndsAt: this.usageCycleEndsAt,
          willPatchesPause: this.willPatchesPause,
          actionsRemaining: this.actionsRemaining,
          isNearActionLimit: this.isNearActionLimit,
          isOverActionLimit: this.isOverActionLimit,
          willSubscriptionCancelIntentionally: this.willSubscriptionCancelIntentionally,
        })
      })
    },
  }

</script>

<style lang="stylus" scoped>

  p + p
    @apply mt-4

  .breakdown
    @apply p-4
    @apply pt-2

    @apply flex
    @apply gap-2
    @apply flex-col

    .line-item
      @apply flex
      @apply gap-2
      @apply items-center

      svg
        @apply w-4
        @apply h-4
        @apply flex-shrink-0

      .old-value
        @apply w-16
        @apply text-right
        @apply line-through
        @apply flex-shrink-0

        text-decoration-thickness: 1px // @NOTE: this is in tailwind 3 as decoration-1

        +breakpoint(sm)
          @apply w-32

      .new-value
        @apply flex-grow
        @apply text-gray-1000

        .value
          @apply font-bold
          @apply text-primary-500

</style>
